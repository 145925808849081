/* eslint dot-location: 0 */

/**
 * Note to future spelunkers:
 * This file is only actually used on the "forgot password" and "reset password" pages
 */

/**
 * This module is JS that will run on page load and will bootstrap JS verification of forms
 * based on `data-js-validation` attributes in the form. It is intended to be used with forms
 * that have been updated to use the styleguide styling.
 */
import flip from 'lodash/fp/flip';
import flow from 'lodash/fp/flow';
import fromPairs from 'lodash/fp/fromPairs';
import map from 'lodash/fp/map';
import overSome from 'lodash/fp/overSome';
import 'jquery-validation';

const PARAMETERIZED_VALIDATIONS = ['equalTo', 'maxlength', 'minlength'];
const BOOLEAN_VALIDATIONS = ['email'];
const hasAttributeMatching = (match) => (element) => {
  for (const attr of element.attributes) {
    if (attr.name.includes(match)) {
      return true;
    }
  }
  return false;
};
const hasValidationAttribute = overSome([
  hasAttributeMatching('data-js-validation'),
  hasAttributeMatching('required')
]);
const asDataAttribute = (validation) => `data-js-validation-${validation}`;

const collectValidationRulesForElement = (element) => {
  const ruleObj = {};
  if (element.getAttribute('required')) {
    ruleObj.required = true;
  }
  for (const validation of PARAMETERIZED_VALIDATIONS) {
    const value = element.getAttribute(asDataAttribute(validation));
    if (value) {
      ruleObj[validation] = value;
    }
  }
  for (const validation of BOOLEAN_VALIDATIONS) {
    if (element.getAttribute(asDataAttribute(validation))) {
      ruleObj[validation] = true;
    }
  }

  return [element.id, ruleObj];
};
const collectValidationRulesForElements = flow(map(collectValidationRulesForElement), fromPairs);

$(() => {
  $('[data-js-focus]').trigger('focus');
  $('form[data-js-validate]').each(function () {
    const $form = $(this);
    const elementsToValidate = $form.find('input').filter(flip(hasValidationAttribute));
    const rules = collectValidationRulesForElements(elementsToValidate);

    $form.validate({
      errorClass: 'text-input-error',
      rules
    });
  });
});
